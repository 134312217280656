import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  return (
    <div
      style={{
        textAlign: "center"
      }}
    >
      <link href="https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap" rel="stylesheet"/>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Not affliated with The Walt Disney Company, but built by Disney Enthusiasts
      </footer>
    </div>
  )
}

export default Layout
